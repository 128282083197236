<template>
  <section class="mt-5 text-center">
    <button
      class="text-center border py-1 px-5 rounded"
      @click="loginWithGoogle"
    >
      Login With Google
    </button>
  </section>
</template>

<script>
import firebase from "../../utilities/mixins/firebase.js";
export default {
  methods: {
    loginWithGoogle() {
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(() => {
          this.$emit("close-login-from-google");
        });
    },
  },
};
</script>