<template>
  <AppHeader :isLoggedIn="isLoggedIn" @open-login-modal="isLoginOpen = true" />
  <div class="w-full flex">
    <router-view></router-view>
  </div>
  <LoginModal v-if="isLoginOpen" @close-login="isLoginOpen = false" />
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import LoginModal from "./components/LoginModal.vue";
import firebase from "./utilities/mixins/firebase.js";

export default {
  name: "App",
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if(user){
         this.isLoggedIn = true;
         this.authUser = user;
      }else{
         this.isLoggedIn = false;
         this.authUser = {};
      }
    });
  },
  components: {
    AppHeader,
    LoginModal,
  },
  data() {
    return {
      isLoginOpen: false,
      isLoggedIn : false,
      authUser:{},
    };
  },
};
</script>

<style>
</style>
