<template>
<div class="m-auto">
    <h1 class="w-full text-center text-3xl my-4 font-bold">Welcome to Our First Project </h1>
    <p class="text-center">You can switch given above tab between two page</p>
</div>
</template>
<script>
export default {
    
}
</script>