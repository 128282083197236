<template>
 <button @click="isModalOpen=true" class="px-2 py-2 border rounded my-4">Add User</button>
    <teleport to="body">
    <Modal v-if="isModalOpen" @close="isModalOpen=false">
      <template #title>
        Add New User
      </template>
      <template #body>
        <form @submit.prevent="submit">
          <div class="p-2">
            <label for="User Name">Name</label>
            <input type="text" placeholder="User Name" class="w-full p-2 rounded border" v-model="state.form.name">
          </div>
          <div class="p-2">
            <label for="User Email">Email</label>
            <input type="email" placeholder="User Email" class="w-full p-2 rounded border" v-model="state.form.email">
          </div>
          <div class="p-2">
            <label for="User Avatar">Avatar</label>
            <input type="text" placeholder="Avatar Url" class="w-full p-2 rounded border" v-model="state.form.avatar">
          </div>
          <div class="p-2">           
            <button type="submit"  class="w-full p-2 rounded border hover:bg-gray-300 shadow">Create</button>
          </div>
        </form>
      </template>
    </Modal>
  </teleport>
</template>

<script>
import Modal from '../Modal';
import  {ref, reactive } from 'vue';
import axios from '../../plugins/axios.js';
export default {
components:{Modal},
setup(_,{emit}){
     const isModalOpen=ref(false);
     const state= reactive({
          form:{
        name:"",
        email:"",
        avatar:"",
      }
     })
        async function submit(){
      const {data} = await axios.post('/users',state.form);
      emit('new-user-added',data);
      // console.log(data);
    //   state.users.push(data);
      state.form.email = '';
      state.form.name = '';
      state.form.avatar = '';
      isModalOpen.value = false;

    }
     return {isModalOpen,state,submit};
}
}
</script>

<style>

</style>