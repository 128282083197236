<template>
  <nav
    class="w-full bg-gradient-to-r from-blue-800 to-blue-600 text-white px-4 py-2"
  >
    <router-link
      v-for="item in list"
      :key="item.to"
      :to="item.to"
      class="text-white text-2xl mx-2"
      >{{ item.title }}</router-link
    >
    <button v-if="isLoggedIn" class="mx-2 text-2xl" @click="logout">Logout</button>
    <button v-else class="mx-2 text-2xl" @click="$emit('open-login-modal')">Login</button>
    
  </nav>
</template>


<script>
import firebase from "../utilities/mixins/firebase.js";
export default {
  props:{isLoggedIn:Boolean},
  data() {
    return {
      list: [
        { title: "Home", to: "/" },
        { title: "Dc-Heroes", to: "/dc-heros" },
        { title: "Calender", to: "/calender" },
        { title: "Markdown", to: "/markdown" },
        { title: "Slider-Carousel", to: "/slider" },
        { title: "Calculator", to: "/calculator" },
        { title: "Modal", to: "/reuseablemodal" },
        { title: "Chat", to: "/chat" },
        { title: "UserCrud", to: "/usercrud" },
      ],
    };
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut();
        // .then((res) => {})
        // .catch((e) => {});
    },
  },
};
</script>


<style>
</style>