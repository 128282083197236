import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAr3BT8QUOnWXjMxchPEEB7Q28Df2XnyCc",
  authDomain: "vue3-c1070.firebaseapp.com",
  projectId: "vue3-c1070",
  storageBucket: "vue3-c1070.appspot.com",
  messagingSenderId: "342247976607",
  appId: "1:342247976607:web:744d83b4c313104c8e7caa"
};
firebase.initializeApp(firebaseConfig);
const db = firebase.database();
export const chatsRef = db.ref("chats");
export default firebase;