<template>
  <div class="m-auto">
    <h1 class="w-full text-center text-3xl my-4 font-bold">
      Dc Heros {{ herosCount }}
    </h1>
    <ul>
      <li
        class="flex justify-between"
        v-for="(hero, index) in dcHeros"
        :key="hero.name"
      >
        <div>{{ hero.name }} <input class="ml-2" /></div>
        <button class="ml-2" v-on:click="remove(index)">X</button>
      </li>
    </ul>
    <form class="mt-10" @submit.prevent="addHero">
      <input
        class="border rounded mr-2"
        v-model="newHero"
        placeholder="Type Hero Name Here"
        ref="newHeroRef"
      />
      <button
        class="boredr rounded bg-gradient-to-t from-red-700 to-pink-500 text-white py-1 px-4"
        type="Submit"
      >
        Add Hero
      </button>
    </form>
  </div>
</template>
<script>
export default {
  computed: {
    herosCount() {
      return this.dcHeros.length;
    },
  },
  methods: {
    addHero() {
      if (this.newHero != "") {
        this.dcHeros.unshift({ name: this.newHero });
        this.newHero = "";
      }
    },
    remove(index) {
      this.dcHeros = this.dcHeros.filter((hero, i) => i != index);
    },
  },
  data() {
    return {
      newHero: "",
      fname: "chandan",
      lname: "vishwakarma",
      dcHeros: [
        { name: "SuperGirl" },
        { name: "Flash" },
        { name: "Batman" },
        { name: "Arrow" },
        { name: "SpiderMan" },
        { name: "AntMan" },
        { name: "SuperMan" },
      ],
    };
  },
  mounted() {
    console.log(this.$refs.newHeroRef.focus());
  },
};
</script>