<template>
  <section class="flex w-full">
    <div class="m-auto">
      <h1 class="text-2xl text-center">Calculator</h1>
      <p
        class="text-3xl text-right mt-10 overflow-x-scroll w-40 h-20"
        style="direction: rtl"
      >
        {{ currentNumber }}
      </p>
      <div class="h-10">
        <small v-if="selectedOperation"
          >{{ prevNum }}{{ selectedOperation }}{{ currentNumber }}</small
        >
      </div>
      <div class="grid grid-cols-4 gap-1">
        <button
          @click="pressed('1')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          1
        </button>
        <button
          @click="pressed('2')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          2
        </button>
        <button
          @click="pressed('3')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          3
        </button>
        <button
          @click="pressed('+')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          +
        </button>
        <button
          @click="pressed('4')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          4
        </button>
        <button
          @click="pressed('5')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          5
        </button>
        <button
          @click="pressed('6')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          6
        </button>
        <button
          @click="pressed('-')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          -
        </button>
        <button
          @click="pressed('7')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          7
        </button>
        <button
          @click="pressed('8')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          8
        </button>
        <button
          @click="pressed('9')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          9
        </button>
        <button
          @click="pressed('*')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          *
        </button>
        <button
          @click="pressed('c')"
          class="p-2 border rounded shadow h-10 w-10"
        >
          C
        </button>
        <button
          @click="pressed('0')"
          class="p-2 border rounded shadow h-10 w-10"
        >
          0
        </button>
        <button
          @click="pressed('=')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          =
        </button>
        <button
          @click="pressed('/')"
          class="p-2 border rounded shadow w-10 h-10"
        >
          /
        </button>
      </div>
    </div>
  </section>
</template>


<script>
import { onMounted, onUnmounted, ref } from "vue";
export default {
  setup() {
    const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    const operations = ["+", "-", "*", "/"];
    const currentNumber = ref("");
    const prevNum = ref("");
    const selectedOperation = ref("");
    function pressed(value) {
      if (value === "=" || value == "Enter") {
        calculate();
      } else if (value == "c") {
        clear();
      } else if (operations.includes(value)) {
        applyOperation(value);
      } else if (numbers.includes(value)) {
        appendNumber(value);
      }
    }
    function appendNumber(value) {
      currentNumber.value = currentNumber.value + value;
    }
    function applyOperation(value) {
      calculate();
      prevNum.value = currentNumber.value;
      currentNumber.value = "";
      selectedOperation.value = value;
    }
    function calculate() {
      if (selectedOperation.value == "+") {
        sum();
      } else if (selectedOperation.value == "-") {
        substract();
      } else if (selectedOperation.value == "*") {
        multiply();
      } else if (selectedOperation.value == "/") {
        divide();
      }
      prevNum.value = "";
      selectedOperation.value = "";
    }
    function multiply() {
      currentNumber.value = prevNum.value * currentNumber.value;
    }
    function sum() {
      currentNumber.value = +prevNum.value + +currentNumber.value;
    }
    function substract() {
      currentNumber.value = prevNum.value - currentNumber.value;
    }
    function divide() {
      currentNumber.value = prevNum.value / currentNumber.value;
    }
    const clear = () => {currentNumber.value = "";}
    const handleKeydown = (e) => {pressed(e.key);}

    onMounted(() => {
      window.addEventListener("keydown", handleKeydown);
    });
    onUnmounted(() => {
      window.removeEventListener("keydown", handleKeydown);
    });
    return { currentNumber, pressed, selectedOperation, prevNum };
  },
};
</script>