<template>
  <div class="flex w-full flex-wrap">
    <h1 class="w-full text-center text-3xl my-4 font-bold">Markdown App</h1>
    <section class="flex m-auto w-10/12 h-screen">
      <article class="w-1/2 border">
        <textarea
        ref="markdownTextArea"
          class="w-full h-full"
          :value="text"
          @input="update"
        ></textarea>
      </article>
      <article
        class="w-1/2 border bg-gray-100 break-all"
        v-html="markedText"
      ></article>
    </section>
  </div>
</template>
<script>
import marked from "marked";
import debounce from "../utilities/mixins/debounce";
export default {
  mixins: [debounce],
  data() {
    return {
      text: "",
    };
  },
  computed: {
    markedText() {
      return marked(this.text);
    },
  },
  methods: {
    update(e) {
      const task = () => {
        this.text = e.target.value;
      };
      this.debounce(task, 500);
    },
  },
  mounted() {
      this.$refs.markdownTextArea.focus();
  },
};
</script>