<template>
  <section class="flex w-full">
    <div class="m-auto">
      <h1 class="text-center text-2xl">Real Time Chat</h1>
      <div class="border rounded-lg">
        <div class="h-64 p-2" style="overflow-y: scroll">
          <div
            v-for="chat in state.chats"
            :key="chat.message"
            class="w-full"
            :class="chat.userId == state.userId ? 'text-right text-blue-400':'text-left text-red-400'"
          >
            {{ chat.message }}
          </div>
        </div>
        <div class="h-8 p-2">
          <input
            type="text"
            v-model="state.message"
            placeholder="Start Typing..."
            class="p-1 border rounded shadow"
            @keydown.enter="addMessage"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, reactive } from "vue";
import firebase,{chatsRef} from "../utilities/mixins/firebase.js";
export default {
  setup() {
    const state = reactive({
      chats: [],
      message: "",
      collection: null,
      userId: null,
    });

    onMounted(async () => {      
      // const data = await chatsRef.once("value");
      // state.chats = data.val();
      
        chatsRef.on("child_added", (snapshot) => {
        state.userId = firebase.auth().currentUser.uid;
        state.chats.push({key:snapshot.key,...snapshot.val()})
          // state.chats = snapshot.val();
          // console.log(snapshot.key);
        });
      
    });
    function addMessage() {
      const newChat = chatsRef.push();

      newChat.set({ userId: state.userId, message: state.message });
      state.message = "";
    }
    return { state, addMessage };
  },
};
</script>