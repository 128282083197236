import {createRouter, createWebHistory} from 'vue-router';
import DcHeros from './pages/DcHeros';
import Calender from './pages/Calender';
import Home from './pages/Home';
import Markdown from './pages/Markdown';
import Slider from './pages/Slider';
import Calculator from './pages/Calculator';
import Chat from './pages/Chat';
import UserCrud from './pages/UserCrud';
import ReuseableModal from './pages/ReuseableModal';
const routes = [
    {path:'/',component:Home},
    {path:'/dc-heros',component:DcHeros},
    {path:'/calender',component:Calender},
    {path:'/markdown',component:Markdown},
    {path:'/slider',component:Slider},
    {path:'/calculator',component:Calculator},
    {path:'/reuseablemodal',component:ReuseableModal},
    {path:'/chat',component:Chat},
    {path:'/usercrud',component:UserCrud},
];
const router = createRouter({ 
    history : createWebHistory(),   
    routes,
});
export default router;