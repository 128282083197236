<template>
  <div>
    <section
      @click="close"
      class="z-20 h-screen w-screen bg-gray-500 fixed top-0 opacity-50"
    ></section>
    <div class="absolute inset-0">
      <div class="flex h-full">
        <div class="z-30 m-auto bg-white p-2 rounded shadow w-1/3">
          <div class="p-2 border">
            <h1 class="text-2xl text-center font-bold">Login</h1>
            <GoogleLogin @close-login-from-google="close" />
            <p class="my-3 text-center">Or</p>
            <form class="p-2 my-2" @submit.prevent="submit">
              <div class="my-4">
                <label for="Email">Email or UserName</label>
                <input
                  v-model="email"
                  type="text"
                  class="rounded shadow p-2 w-full"
                  placeholder="abc@gmail.com"
                />
              </div>
              <div class="my-4">
                <label for="Password">Password</label>
                <input
                  ref="emailRef"
                  v-model="password"
                  type="password"
                  class="rounded shadow p-2 w-full"
                  placeholder="*********"
                />
              </div>
              <div class="my-4">
                <button
                  type="submit"
                  class="bg-green-500 px-8 py-2 rounded text-white w-full"
                >
                  <span v-if="!isLoading">Login</span>
                  <span v-else>Please Wait ...</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../utilities/mixins/firebase.js";
import GoogleLogin from "../components/Login/GoogleLogin.vue";
export default {
  components: { GoogleLogin },
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
    };
  },
  methods: {
    submit() {
      this.isLoading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((res) => {
          console.log(res);
          this.email = "";
          this.password = "";
          this.isLoading = false;
          this.close();
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    close() {
      this.$emit("close-login");
    },
  },
  mounted() {
    this.$refs.emailRef.focus();
  },
};
</script>