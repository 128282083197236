<template>
  <section class="flex w-full flex-wrap">
    <div class="m-auto">
      <h1 class="text-2xl my-5">Reuseable Modal</h1>
      <Modal v-if="isModal1Open" @close="isModal1Open = false">
        <template v-slot:title> Modal 1 </template>
        <template v-slot:body>
          <p>This is the body slot modal 1</p>
        </template>
      </Modal>
      <button
        @click="isModal1Open = true"
        class="border rounded px-3 py-1 mx-4 bg-blue-500 text-white"
      >
        Modal 1
      </button>

      <Modal v-if="isModal2Open" @close="isModal2Open = false">
        <template v-slot:title> Modal 2 </template>
        <template v-slot:body>
          <p>This is the body slot modal 2</p>
        </template>
      </Modal>
      <button
        @click="isModal2Open = true"
        class="border rounded px-3 py-1 mx-4 bg-blue-500 text-white"
      >
        Modal 2
      </button>
    </div>
  </section>
</template>

<script>
import Modal from "../components/Modal.vue";
import { ref } from "vue";
export default {
  components: { Modal },
  setup() {
    const isModal1Open = ref(false);
    const isModal2Open = ref(false);
    return { isModal1Open, isModal2Open };
  },
};
</script>

<style>
</style>