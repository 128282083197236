<template>
  <div class="flex w-full flex-wrap">
    <div class="flex w-full flex-wrap relative">
      <div
        class="absolute w-full"
        v-for="(color, index) in sliders"
        :key="color"
        style="overflow: hidden"
      >
        <transition name="fade">
          <div
            class="h-full"
            v-if="currentSlide == index"
            :class="color"
            style="height: 94.9vh"
          ></div>
        </transition>
      </div>
      <!-- <div
        v-if="currentSlide == 1"
        class="absolute w-full bg-yellow-600"
        style="height: 350px"
      ></div>
      <div
        v-if="currentSlide == 2"
        class="absolute w-full bg-red-500"
        style="height: 350px"
      ></div> -->
    </div>
    <!-- <div class="my-10 flex w-full">
      <div class="m-auto">
        <transition name="fade">
          <h1 v-if="isTitleShowing" class="text-3xl">Slider Carousal</h1>
        </transition>

        <button
          @click="isTitleShowing = !isTitleShowing"
          class="px-4 rounded border mt-2"
          style="margin-left: 2.5rem"
        >
          Toggle Text
        </button>
      </div>
    </div> -->
    <div class="w-full">
      <div class="absolute flex justify-center w-full bottom-10">
        <div
          v-for="(slider, index) in sliders"
          :key="slider"
          @click="makeActive(index)"
          class="w-4 h-4 rounded-full mx-2 cursor-pointer shadow-md"
          :class="currentSlide == index ? 'bg-white' : 'bg-gray-300'"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSlide: 0,
      interval: "",
      isTitleShowing: true,
      sliders: [
        "bg-yellow-600",
        "bg-red-500",
        "bg-blue-600",
        "bg-green-400",
        "bg-pink-400",
      ],
    };
  },
  mounted() {
    this.interval = setInterval(() => {
      this.currentSlide =
        this.currentSlide == this.sliders.length - 1
          ? 0
          : this.currentSlide + 1;
    }, 2000);
  },
  methods:{
makeActive(index){
this.currentSlide=index;
}
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease;
}
.fade-enter-from {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
</style>